window.Home = new (function () {

    var that = this;

    /**
     * Banner
     */
    this.sldrBannerHome = function(){
       
        $('.mscs-heroSldr').slick({
            arrows: true,
            dots:false,
            slidesToScroll: 1,
            slidesToShow:1,
            infinite:false
        });
          
    }
    /**
     * protos
     */
    this.sldrProtoHome = function(){
       
        $('.mscs-sldrProto').slick({
            arrows: true,
            dots:false,
            slidesToScroll: 1,
            slidesToShow:3,
            infinite:false,
            prevArrow: '<button class="slide-arrow prev-arrow"></button>',
            nextArrow: '<button class="slide-arrow next-arrow"></button>',
            responsive: [

                {
                  breakpoint: 9999999,
                  settings: {
                    slidesToShow: 3,
                    
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                   
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                   
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
        });
          
    }

    this.sldrGalleryHome = function(){
      $('.mscs-amenities_sldr').slick({
        arrows: false,
        dots:true,
        slidesToScroll: 1,
        slidesToShow:1,
        responsive: [
          {
            breakpoint: 9999999,
            settings: {
              centerMode:true,
              variableWidth:true,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              centerMode:false,
              variableWidth:false,
            }
          }
        ]
      });
    }

    this.detailProtoSldr= function(){
       
      $('.mscs-protoSldrGal').slick({
          arrows: false,
          dots:true,
          slidesToScroll: 1,
          slidesToShow:1,
          infinite:false
      });
    }

    this.detailProtoSldrGallery= function(){
       
      $('.mscs-det_galSdr').slick({
          arrows: false,
          dots:true,
          slidesToScroll: 1,
          slidesToShow:1,
          infinite:false,
          prevArrow: $(this).next().find(".slide-m-prev"),
          nextArrow: $(this).next().find(".slide-m-next"),
      });
        
    }
    /**
     * Init
     */
    this.init = function () {

        that.sldrBannerHome();
        that.sldrProtoHome();
        that.sldrGalleryHome();
        that.detailProtoSldr();

    };

})();