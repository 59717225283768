window.Tabs = new (function () {

    let that = this;

    this.init = function () {

        that.towerTabs();

    };

    this.towerTabs = function(){
        $('.mscs-protoNav').simpleTab({
            container: '.mscs-torres',
            handler: '.mscs-torres>li',
    		content: '.mscs-torres_wrapp',
            attribute: 'href',
            caller: function () {
                $('.mscs-sldrProto').filter('.slick-initialized').slick('setPosition');
            }
        });
    }

})();