
(function(){
  
  let onScroll = function(){
    $(window).scroll(function () {
        if ($(window).scrollTop() > 30)
            $('header').addClass('stick')
        else
            $('header').removeClass('stick')
    })
  }
  
  let menu = function(){
      $('body').toggleClass('noScroll');
  };
    
  $('.mscs-hamburger').on('click', menu);

  onScroll();


 })();