window.Residencial = new (function () {

    var that = this;

    this.detailProtoSldr= function(){
       
      $('.mscs-protoSldrGal').slick({
          arrows: false,
          dots:true,
          slidesToScroll: 1,
          slidesToShow:1,
          infinite:false
      });
    }

    this.detailProtoSldrGallery= function(){
       
      $('.mscs-det_galSdr').slick({
          arrows: true,
          dots:false,
          slidesToScroll: 1,
          slidesToShow:1,
          infinite:false,
          prevArrow: $('.mscs-det_galSdr').next().find(".slide-m-prev"),
          nextArrow: $('.mscs-det_galSdr').next().find(".slide-m-next"),
      });
        
    }
    /**
     * Init
     */
    this.init = function () {

        that.detailProtoSldrGallery();
        that.detailProtoSldr();

    };

})();