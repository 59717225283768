import $ from 'jquery';
window.$ = window.jQuery = $;
require('./bootstrap');
require('slick-carousel');
require('./inview');
require('./jdTab');
require('./main');
require('./home');
require('./tabs');
require('./residencial');
require('./parallax');